import React from 'react'

const Tool_Report = ({dash_json,chnageRoute}) => {
  return  <>
  <section className='p-4 bg-white'>
  <div className="sectionQrPanelTotal ">
    <div className="container">
      <div className="sectionQraboxTotal">
        <div className="row align-items-center">
          {/* <!-- left Area --> */}
          <div className="col-lg-6" style={{color:'#292929',fontSize:'20px'}}>Total Score</div>
          {/* <!-- left Area --> */}
          {/* <!-- left Area --> */}
          <div className="col-lg-6 text-end boxTotal">
            <h6>{dash_json?.category_ranking?.all_ranking_score?.result}</h6>
          </div>
          {/* <!-- left Area --> */}
        </div>
      </div>
    </div>
    <section className="sectionQrPanel pb-0">
    <div className="container">
      <div className="row">
        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Security and Privacy')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/01.svg" />
              Security and Privacy
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.security_privacy?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.security_privacy?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-01"
                    style={{ width: `${+dash_json?.category_ranking?.security_privacy?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Performance and Technical')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/02.svg" />
              Performance and Technical
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.performance_technical?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.performance_technical?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-02"
                    style={{ width: `${+dash_json?.category_ranking?.performance_technical?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Integration and Services')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/03.svg" />
              Integration &amp; Services
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.integration_service?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.integration_service?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-03"
                    style={{ width: `${+dash_json?.category_ranking?.integration_service?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Mobile Responsiveness')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/04.svg" />
              Mobile Responsiveness
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.mobile_responsiveness?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.mobile_responsiveness?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-04"
                    style={{ width: `${+dash_json?.category_ranking?.mobile_responsiveness?.percent}%`}}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Content and Information')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/05.svg" />
              Content and Information
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.content_information?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.content_information?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-05"
                    style={{ width: `${+dash_json?.category_ranking?.content_information?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Interactivity and Engagement')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/06.svg" />
              Interactivity and Engagement
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.interactivity_engagement?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.interactivity_engagement?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-06"
                    style={{ width: `${+dash_json?.category_ranking?.interactivity_engagement?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Branding and Visual Identity')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/07.svg" />
              Branding and Visual Identity
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.branding_visual?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.branding_visual?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-07"
                    style={{ width: `${+dash_json?.category_ranking?.branding_visual?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <!-- ./box 01 --> */}

        <div className="col-lg-4" style={{cursor:'pointer'}} onClick={()=>chnageRoute('Navigation')}>
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/08.svg" />
              Navigation
              <h4>
                <span>
                  <i className="ri-bar-chart-fill"></i> Score{" "}
                </span>{" "}
                {dash_json?.category_ranking?.navigation?.result}
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  {/* <span className="tx-13 font-weight-semibold">
                    India
                  </span> */}
                  <div className="ms-auto">
                    <span className="number-font">{dash_json?.category_ranking?.navigation?.percent}%</span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-08"
                    style={{ width: `${+dash_json?.category_ranking?.navigation?.percent}%` }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {/* <div className="col-lg-4" style={{cursor:'pointer'}} >
          <div className="sectionQrabox">
            <a >
              <img src="/dashboardImage/icon/09.svg" />
              Accessibility
              <h4>
                Pending
              </h4>
              <div className="mt-2 sectionQrProgressArea">
                <div className="d-flex">
                  <span className="tx-13 font-weight-semibold">
                    
                  </span>
                  <div className="ms-auto" style={{marginTop:'10px'}}>
                    <span className="number-font"></span>
                  </div>
                </div>
                <div className="progress ht-8 br-5">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated bg-09"
                    style={{ width: "0%" }}
                  ></div>
                </div>
              </div>
            </a>
          </div>
        </div> */}
        {/* <!-- ./box 01 --> */}

        {/* <!-- ./box 01 --> */}
      </div>
    </div>
  </section>
  </div>
  </section>
  
  {/* <!-- ./section new 02 -->
  <!-- section new 03 --> */}
  
  </>
}

export default Tool_Report
