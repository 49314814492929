import React, { useState } from 'react';
import Tool_Report from '../Dashboard/Report/Tool_Report';
import Survey_Report from '../Dashboard/Report/Survey_Report';

const TabsComponent = ({dash_json,chnageRoute}) => {
  const [activeTab, setActiveTab] = useState('content1');

  const contentStyle = {
    padding: '20px',
    background: '#fff',
    color: '#999',
    borderBottom: '2px solid #f0f0f0',
    display: 'none',
  };
  return (
    <div class="tab_container tabPanelArea">
        <div className='tabBtnArea d-flex gap-1 m-auto justify-content-center'>
        <div>
    <input id="tab1" type="radio" name="tabs" checked={activeTab === 'content1' ? true : false} style={{display:'none'}} />
    <label for="tab1" className={`tabLabelBtn ${activeTab === 'content1' ? "active": ""}`}  onClick={()=>setActiveTab('content1')}>Website's Performance Report</label>
    </div>

    {/* <div>
    <input id="tab2" type="radio" name="tabs" checked={activeTab === 'content2' ? true : false} style={{display:'none'}} />
    <label for="tab2" className={`tabLabelBtn ${activeTab === 'content2' ? "active" : ""}`}  onClick={()=>setActiveTab('content2')}>Survey Report</label>
    </div> */}
    </div>
    {activeTab === 'content1' &&
    <Tool_Report dash_json={dash_json} chnageRoute={chnageRoute} />
     }

    {activeTab === 'content2' &&
    <Survey_Report />}
</div>

  );
};

export default TabsComponent;
