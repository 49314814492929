import React from 'react';
import { ClipLoader } from 'react-spinners';

const Dash_Modal = ({siteData}) => {
  return (
    <>
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center horizontally and vertically
        height: '200px',
        width: '500px',
        backgroundColor: 'white',
        zIndex: 999999999,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
      }}>
        <h1 style={{
          color: '#333',
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          fontSize:'18px',
        //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}>The report for {siteData && siteData[0]?.name} is under process, Please wait for a while!</h1>
        <ClipLoader color='black' />
      </div>
    </>
  );
}

export default Dash_Modal;
