import React,{useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Dash_Footer from './Dash_Footer';
import Common_Tab from './Common_Tab';
import LightboxComp from './Lightbox';


ChartJS.register(ArcElement, Tooltip, Legend);
const Common_Overview = ({dash_json,chnageRoute, isBlurred}) => {
  const graph1 = {
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };
  const headerRoute = [
    { id: 1, title: "Overview", route: "#" },
    { id: 2, title: "Security and Privacy", route: "#" },
    { id: 3, title: "Performance and Technical", route: "#" },
    { id: 4, title: "Integration & Services", route: "#" },
    { id: 5, title: "Mobile Responsiveness", route: "#" },
    { id: 6, title: "Content and Information", route: "#" },
    { id: 7, title: "Interactivity and Engagement", route: "#" },
    { id: 8, title: "Branding and Visual Identity", route: "#" },
    { id: 9, title: "Navigation", route: "#" },
    // { id: 10, title: "Accessibility", route: "#" },
    // { id: 8, title: "Considerations", route: "#" },
    // { id: 8, title: "", route: "#" },
  ];
  const sliderdata = ["1"];


  const replace=(detail,title)=>{
    const newDetailData = `${detail}`;
    if(newDetailData.includes(".")){
    const newDetail=parseFloat(detail).toFixed(2);
    if(title == 'Load time'){
    return  `${newDetail}s`
    }
    else if(title == 'Page size'){
      return `${newDetail} Mb`
    }
    else{
      return newDetail
    }
  }
  return detail
  }

  const charts = [
    {
      id: 1,
      title: "Performance grade",
      icon: "ri-dashboard-3-line",
      detail: "67",
      grade: "C",
    },
    {
      id: 2,
      title: "Page size",
      icon: "ri-file-text-line",
      detail: "8.75MB",
    },
    {
      id: 3,
      title: "Load time",
      icon: "ri-history-line",
      detail: "22s",
    },
    {
      id: 4,
      title: "Requests",
      icon: "ri-file-edit-line",
      detail: "84",
    },
  ];

  charts.filter((item)=>{
    if(item?.title == 'Performance grade' ){
      item.detail = dash_json?.performance_grade
    }
    else if(item?.title == 'Requests'){
      item.detail = dash_json?.requests
    }
    else if(item?.title == 'Page size'){
      item.detail = dash_json?.page_size
    }
    else{
      item.detail = `${parseFloat(dash_json?.load_time).toFixed(2)}s`
    }
  })
  const graph = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <>
   <div className={`container-fluid p-0 ${isBlurred && "blur-content"}`}>
            {/* <!-- section 01 --> */}
            <section className="ruralDevtSection">
              <div className="container">
                <div className='row'>
                <div className='col-lg-12 align-items-center pt-0 pb-0'>
                <div className="d-flex gap-3 MinistryLogoArea align-items-center mb-4">
                  <section style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                    <div style={{display:'flex',gap:'15px'}}>
                    <div>
                          <img src="https://checkbrand.online/frontend/images/profile_pic_17035912201424883525.jpeg" style={{height:'100px',objectFit:'cover',padding:'0px'}} />
                        </div>
                        <div>
                          <span className="devtContArea">
                            <i className="ri-bar-chart-fill"></i> Analysis Report{" "}
                          </span>
                          <h1 style={{fontSize:'1.5em',color:'#222'}}>
                            {dash_json?.website_name}
                          </h1>
                          <a href={dash_json?.website_url} target='_blank'>{dash_json?.website_url}</a>
                        </div>
                    </div>
                    {/* <div>
                          <h1 style={{fontSize:'1.5em',color:'#222'}}>
                            <a href={dash_json?.website_url} target='_blank'>{dash_json?.website_name}</a>
                          </h1>
                        </div> */}
                  </section>
                       
                       
                          </div>
                </div>
                </div>
              </div>
            </section>
            <section>
            <Common_Tab dash_json={dash_json} chnageRoute={chnageRoute} />
            </section>
            <section>
              <div>
              <div className="col-lg-12 flex pt-0 pb-0">
                {/* <h1 style={{fontSize:'2em',color:'black',padding:'10px'}}>Detailed Data</h1> */}
                  <div className="banner-slider">
                <div className="slider-img">
                {sliderdata.map((index)=>{
                return <div key={index}>
                  <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2">
                    {dash_json?.performaceGrade?.map((item,index)=>{
                    return<div className="col" key={index}>
                      <div className="card bRadius-15">
                        <div className="card-body p-0">
                          <div className="p-3 pb-0 cardBox">
                            <div className="float-end iconBoxAr fs-3 lh-base">
                              <i className={item?.title == 'Performance grade' && 'ri-dashboard-3-line' 
                              || item?.title == 'Page size' && 'ri-file-text-line' || item?.title == 'Load time' && 'ri-history-line' 
                              || item?.title == 'Requests' && 'ri-file-edit-line' }></i>
                            </div>
                            <p className="mb-0">{item?.title}</p>
                            {item.grade ? <h5 className="mb-0">
                             <span style={{marginRight:'5px'}}>{item?.grade}</span>{replace(item?.detail,item?.title)}
                            </h5> : <h5 className="mb-0">
                              {replace(item?.detail,item?.title)}
                            </h5>
                            }
                          </div>
                          <div className="" id="chart1">
                          <ReactApexChart
                              options={graph1?.options}
                              series={graph1?.series}
                              type="area"
                              height={150}
                            />
                          </div>
                        </div>
                      </div>
                    </div>})}
                  </div>
                  {/* <div className="chartboxArea mt-3">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <Doughnut data={graph} />
                      </div>
                      <div className="col-lg-6">
                        {dash_json?.pieData?.map((item)=>{
                        return <div className="progress-wrapper mb-3" key={item.id}>
                          <div className="d-flex align-items-end">
                            <div className="text-secondary">
                              {item?.title}
                            </div>
                            <div className="text-success ms-auto" style={{fontWeight:"500"}}>{item.percent == 'N/A' ? item.percent : item.percent}</div>
                          </div>
                          <div
                            className="progress mt-2"
                            style={{ height: "6px" }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: item.percent, background: 
                                item?.title == 'Mobile Friendliness' && '#623aa3' 
                              || item?.title == 'Backlinks on the pages' && '#ec428b'
                              ||  item?.title == 'Domain Age, URL, and Authority' && '#f49e3f'
                              || item?.title == 'Keywords on the pages' && '#0d6efd'}}
                            ></div>
                          </div>
                        </div>})}
                      </div>
                    </div>
                  </div> */}
                </div>})}
                </div>
              </div>
                  </div>
              </div>
            </section>
            <section>
            <div className="" id="chart1" style={{background:'white',padding:'50px',borderRadius:'60px'}}>
              
                          <ReactApexChart 
                              options={graph1?.options}
                              series={graph1?.series}
                              type="area"
                              height={400}
                            />
                          </div>
            </section>
            <Dash_Footer />
          </div>
  </>
}

export default Common_Overview
