import logo from './logo.svg';
import './App.css';
import Home from './component/Home/Home';
import { BrowserRouter as Router , Routes, Route, Navigate  } from 'react-router-dom';
import Dashboard from './component/Dashboard/Dashboard'
import Login from './auth/Login';
import Sites from './component/Sites/Sites';
import { useState } from 'react';
function App() {

  return <>
  <Router>
    <Routes>
      {/* <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/sites/:id' element={ <Sites />} />
      <Route path='/dashboard/:id' element={<Dashboard />} /> */}
      <Route path='/' element={<Dashboard />} />
    </Routes>
  </Router>
  </>
}

export default App;
