import React, { useEffect, useState } from "react";
import Common_Overview from "../common/Common_Overview";
import Common_Dash from "../common/Common_Dash";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { BarLoader, BeatLoader, ClipLoader } from "react-spinners";
import Dash_Modal from "../common/Dash_Modal";
import dummyDashData from '../../common/db.json'

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dash_json = {
    website_name: "Lalit kala",
    website_logo:
      "https://rural.nic.in/sites/all/themes/rural/images/emblem-dark.png",
    website_description:
      "Lalit Kala Akademi (National Academy of Art), New Delhi was inaugurated on 5th August 1954 by the then Minister for Education, Maulana Abul Kalam Azad and was registered under the Societies Registration Act 1860, on 11th March 1957. In pursuance of the objectives set out in the Constitution, the organisation functions through its General Council, Executive Board and other Committees.",
    website_url: "https://lalitkala.gov.in/",
    category_rank_last_month: "26",
    organic_Traffic: "3756",
    organic_Keyword: "2456",
    ranking: [
      {
        id: 1,
        title: "Global Rank",
        rank: "23456",
      },
      {
        id: 1,
        title: "Country Rank",
        rank: "23456",
      },
      {
        id: 1,
        title: "Category Rank",
        rank: "23456",
      },
    ],
    performaceGrade: [
      {
        id: 1,
        title: "Performance grade",
        detail: "67",
        grade: "C",
      },
      {
        id: 2,
        title: "Page size",
        detail: "8.75MB",
      },
      {
        id: 3,
        title: "Load time",
        detail: "22s",
      },
      {
        id: 4,
        title: "Requests",
        detail: "84",
      },
    ],
    pieData: [
      { id: 1, title: "Mobile Friendliness", percent: "35.567%" },
      {
        id: 2,
        title: "Backlinks on the pages",
        percent: "56.456%",
      },
      {
        id: 3,
        title: "Domain Age, URL, and Authority",
        percent: "20.678%",
      },
      { id: 4, title: "Keywords on the pages", percent: "64.342%" },
    ],
    performance_suggestion: [
      {
        id: 1,
        title: "Make fewer HTTP requests",
        description:
          "Decreasing the number of components on a page reduces the number of HTTP requests required to render the page, resulting in faster page loads. Some ways to reduce the number of components include: combine files, combine multiple scripts into one script, combine multiple CSS files into one style sheet, and use CSS Sprites and image maps.",
      },
      {
        id: 2,
        title: "Make fewer HTTP requests",
        description:
          "Decreasing the number of components on a page reduces the number of HTTP requests required to render the page, resulting in faster page loads. Some ways to reduce the number of components include: combine files, combine multiple scripts into one script, combine multiple CSS files into one style sheet, and use CSS Sprites and image maps.",
      },
    ],
    web_content_data: [
      { id: 1, title: "Image", percent: "76.6", storage: "8.37" },
      { id: 2, title: "Script", percent: "14.5", storage: "1.59" },
      { id: 3, title: "CSS", percent: "1.2", storage: "0.12" },
      { id: 4, title: "HTML", percent: "4.1", storage: "0.44" },
      { id: 5, title: "Font", percent: "0.3", storage: "0.03" },
    ],
    back_linkData: [
      {
        id: 1,
        title: "Pradhan Mantri Awas Yojana",
        url: "https://nhb.org.in/government-scheme/pradhan-mantri-awas-yojana-credit-linked-subsidy-scheme/",
        anchorText: "Home",
        linkUrl: "https://rural.nic.in/",
        type: "Follow",
      },
      {
        id: 2,
        title: "Indian National Congress - Wikipedia",
        url: "https://en.wikipedia.org/wiki/Indian_National_Congress",
        anchorText: "National Rural Employment Guarantee Act 2005",
        linkUrl:
          "https://rural.nic.in/sites/default/files/nrega/Library/Books/1_MGNREGA_Act.pdf",
        type: "No Follow",
      },
      {
        id: 3,
        title: "telschool",
        url: "https://telschoola.blogspot.com/",
        anchorText: "rural",
        linkUrl: "https://rural.nic.in/",
        type: "No Follow",
      },
      {
        id: 4,
        title: "Council of Ministers| National Portal of India",
        url: "https://www.india.gov.in/my-government/whos-who/council-ministers",
        anchorText: "Contact",
        linkUrl: "https://rural.nic.in/about-us/whos-who",
        type: "Follow",
      },
      {
        id: 5,
        title: "Data & Graphs – National Housing Bank",
        url: "https://nhb.org.in/data-graphs/",
        anchorText: "Home",
        linkUrl: "https://rural.nic.in/",
        type: "Follow",
      },
      {
        id: 6,
        title: "Data & Graphs – National Housing Bank",
        url: "https://nhb.org.in/data-graphs/",
        anchorText: "Home",
        linkUrl: "https://rural.nic.in/",
        type: "Follow",
      },
    ],
    back_link: [
      {
        id: 1,
        title: "No. of Follow Backlinks",
        rank: "#886",
      },
      {
        id: 2,
        title: "No. of No Follow Backlinks",
        rank: "#19962",
      },
    ],
    back_link_types: [
      { id: 1, title: "Text", percent: "35%" },
      { id: 2, title: "Image", percent: "35%" },
      { id: 3, title: "Form", percent: "35%" },
      { id: 4, title: "Frame", percent: "35%" },
    ],
    different_country_data: [
      {
        id: 1,
        title: "India",
        logo: "india.jpg",
        contryImg: "http://webalytics.companydemo.in/india_main.png",
        percent: "93 %",
      },
      {
        id: 2,
        title: "Japan",
        logo: "japan.png",
        contryImg: "http://webalytics.companydemo.in/japan_main.png",
        percent: "94",
      },
      {
        id: 3,
        title: "UK",
        logo: "UK.jpeg",
        contryImg: "http://webalytics.companydemo.in/canada_main.png",
        percent: "89",
      },
      {
        id: 4,
        title: "Canada",
        logo: "Canada.webp",
        contryImg: "http://webalytics.companydemo.in/canada_main.png",
        percent: "76",
      },
      {
        id: 5,
        title: "US",
        logo: "US_main.png",
        contryImg:
          "http://blog.logomyway.com/wp-content/uploads/2011/09/country-flags.jpg",
        percent: "67",
      },
      {
        id: 6,
        title: "Others",
        logo: "others_logo.jpeg",
        contryImg:
          "http://blog.logomyway.com/wp-content/uploads/2011/09/country-flags.jpg",
        percent: "82",
      },
    ],
    last_6_response: [
      {
        code_name: "403",
        code_count: 0,
      },
      {
        code_name: "200",
        code_count: 207,
      },
      {
        code_name: "301",
        code_count: 0,
      },
    ],
    traffic_engagement: [
      { id: 1, title: "Last Month Traffic", data: 169800 },
      { id: 1, title: "Daily Page Visits", data: 169800 },
      { id: 1, title: "Last Month Change", data: 169800 },
      { id: 1, title: "Avg Visit Duration", data: 169800 },
      { id: 1, title: "Pages per Visit", data: 169800 },
      { id: 1, title: "Bounce Rate", data: 169800 },
    ],
  };
  // console.log("Dash Json is",dash_json)
  const [dashList, setDashList] = useState([
    { id: 1, title: "Site Overview", icon: "ri-dashboard-line" },
    { id: 2, title: "Security and Privacy", icon: "ri-calculator-line" },
    { id: 3, title: "Performance and Technical", icon: "ri-layout-line" },
    { id: 4, title: "Integration and Services", icon: "ri-printer-line" },
    { id: 5, title: "Mobile Responsiveness", icon: "ri-file-4-line" },
    { id: 6, title: "Content and Information", icon: "ri-user-fill" },
    {
      id: 7,
      title: "Interactivity and Engagement",
      icon: "ri-git-pull-request-line",
    },
    { id: 8, title: "Branding and Visual Identity", icon: "fa fa-ticket" },
    { id: 9, title: "Navigation", icon: "fa fa-cogs" },
  ]);

  const [initialRoute, setInitialRoute] = useState("");

  useEffect(() => {
    setInitialRoute(dashList[0]?.title);
  }, []);

  const chnageRoute = (item) => {
    setInitialRoute(item);
  };

  const showTab = (route) => {
    switch (route) {
      case dashList[0].title:
        return (
          <>
            <Common_Overview dash_json={dummyDashData?.data} chnageRoute={chnageRoute} isBlurred={isBlurred} />
          </>
        );
      case `Security and Privacy`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.security_privacy}
              slug="security_privacy"
            />
          </>
        );
      case `Performance and Technical`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.performance_technical}
              slug="performance_technical"
            />
          </>
        );
      case `Integration and Services`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.integration_service}
              slug="integration_service"
            />
          </>
        );
      case `Mobile Responsiveness`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.mobile_responsiveness}
              slug="mobile_responsive"
            />
          </>
        );
      case `Content and Information`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.content_information}
              slug="content_information"
            />
          </>
        );
      case `Interactivity and Engagement`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.interactivity_engagement}
              slug="interactivity_engagement"
            />
          </>
        );
      case `Branding and Visual Identity`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.branding_visual}
              slug="branding_visual"
            />
          </>
        );
      case `Navigation`:
        return (
          <>
            <Common_Dash
              dash_json={dummyDashData?.data}
              initialRoute={initialRoute}
              isBlurred={isBlurred}
              questions={dummyDashData?.data?.navigation}
              slug="navigation"
            />
          </>
        );
    }
  };

  const location = useLocation();
  const slug = location.pathname.replace(`/dashboard/`, "");
  const actualSlug = slug.replace("#", "");
  const [siteData, setSiteData] = useState([]);
  const [dashData, setDashData] = useState([]);

  const token = JSON.parse(
    localStorage.getItem("nic-frontend-auth")
  )?.authToken;

  // const getData = () => {
  //   axios
  //     .get(
  //       `https://api.front.webalytics.checkbrand.companydemo.in/api/sites/byuser?user=${actualSlug}`,
  //       {
  //         headers: {
  //           accept: "*/*",
  //           "Auth-Token": `${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setSiteData(res.data?.data);
  //       console.log("Response for Get Sites ", res.data?.data);
  //     })
  //     .catch((error) => {
  //       console.log("Error in getting sites", error);
  //     });
  // };

  useEffect(() => {
    // getData();
    setRefreshType({ ...refreshType, id: actualSlug });
  }, []);

  // useEffect(()=>{
  //   if(siteData.length >0){
  //     console.log("Site Data",siteData[0]?._id?.$oid)
  //     axios.get(`https://api.front.webalytics.checkbrand.companydemo.in/api/sites/insights/${siteData[0]?._id?.$oid}`,{headers: {
  //         accept: "*/*",
  //         "Auth-Token": `${token}`,
  //       },}).then((res)=>{
  //         console.log("Site Report ",res.data)
  //       }).catch((error)=>{
  //         console.log("Error in site report",error)
  //       })
  //   }

  // },[siteData])

  useEffect(() => {
    if (localStorage.getItem("nic-frontend-auth")) {
    } else {
      navigate("/");
    }
  }, []);

  const [showLogout, setShowLogout] = useState(false);
  const [message, showMessage] = useState(false);
  const [notification, setNotification] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);

  const logout = () => {
    localStorage.removeItem("nic-frontend-auth");
    navigate("/");
  };

  const handleNotificationClick = () => {
    setNotification(!notification);
    showMessage(false);
    setShowLogout(false);
  };

  const handleMessageClick = () => {
    showMessage(!message);
    setNotification(false);
    setShowLogout(false);
  };

  const handleLogoutClick = () => {
    setShowLogout(!showLogout);
    setNotification(false);
    showMessage(false);
  };

  const [updateMessage, setUpdateMessage] = useState("");

  // const handleButtonClick = () => {
  //   if(refreshStatus === ''){
  //     refreshApi();
  //   }
  // };

  const questions = {
    security_privacy: [
      {
        id: 1,
        title:
          "Does the website use encryption (e.g, SSL/TLS) to secure a sensitive information during transmission and acess ?",
        description: "Yes",
        ariaControls: "first",
      },
    ],
    performance_technical: [
      {
        id: 1,
        title:
          "Does the website work properly on different browsers like Chrome,Firefox,Safari ?",
        description: "Yes",
        ariaControls: "first",
      },
      {
        id: 2,
        title: "Does search results within 2 seconds ?",
        description: "No",
        ariaControls: "second",
      },
      {
        id: 3,
        title: "Website page load speed for users(0)8 + (1)3-8 sec (2)0-3 sec",
        description: "",
        ariaControls: "third",
      },
    ],
    integration_service: [
      {
        id: 1,
        title:
          "Can users access personalized accounts to avail government services ?",
        description: "No",
        ariaControls: "first",
      },
      {
        id: 2,
        title:
          "Are there end-to-end online services for tasks like applying for permits,benefits,etc ?",
        description: "Yes",
        ariaControls: "second",
      },
      {
        id: 3,
        title:
          "Can documents be downloaded in different formats like PDF,Excel, etc ?",
        description: "Yes",
        ariaControls: "third",
      },
      {
        id: 4,
        title: "Does the website host an online grievance handling system ?",
        description: "Yes",
        ariaControls: "fourth",
      },
    ],
    mobile_responsiveness: [
      {
        id: 1,
        title:
          "The website smoothly adjusts its layout and content to different screen size and devices and content is easily readable and does not require excessive zooming or scrolling on mobile screens.",
        description: "Yes",
        ariaControls: "first",
      },
      {
        id: 2,
        title:
          "Images and media adapt and display properly on different screen sizes and different mobile browsers",
        description: "Yes",
        ariaControls: "second",
      },
      {
        id: 3,
        title:
          "Forms and input fields are user-friendly and responsive on mobile devices",
        description: "Yes",
        ariaControls: "third",
      },
      {
        id: 4,
        title:
          "The size of the target for pointer inputs is atleast 44 by 44 CSS pixels except when Equivalent,Inline,User Agent Control,Essential.",
        description: "No",
        ariaControls: "fourth",
      },
      {
        id: 5,
        title: "View port meta tag present",
        description: "Yes",
        ariaControls: "fifth",
      },
      {
        id: 6,
        title:
          "After the Mobile Performance score on parameters like: Largest Contentfull Paint(LCP),First Input Delay(FID),Cumulative Layout Shift(CLS),First ContentFull Paint(FCP) all lying in the green range on the tool ?",
        description: "No",
        ariaControls: "sixth",
      },
    ],
    content_information: [
      {
        id: 1,
        title:
          "Is the website content are regularly updated with latest govt services,initiatives,policies & regulations,news and events(weekly,bi-weekly,monthly,month+)",
        description: "Yes",
        ariaControls: "first",
      },
      {
        id: 2,
        title:
          "Does the website present information in a clear and understandable manner that can be understood especially by people with lower secondary education level reading ability such as text summary, visual illustrations",
        description: "Yes",
        ariaControls: "second",
      },
      {
        id: 3,
        title:
          "Does the website provide multilingual support, allowing users to access content in different languages? (0)One Language (1) Bilingual (2) Multilingual",
        description: "Yes",
        ariaControls: "third",
      },
      {
        id: 4,
        title: "Is the content free from spelling and grammatical mistakes?",
        description: "Yes",
        ariaControls: "fourth",
      },
      {
        id: 5,
        title:
          "Are relevant and descriptive hyperlinks used throughout the content?",
        description: "Yes",
        ariaControls: "fifth",
      },
      {
        id: 6,
        title:
          "Does the website provide a content search functionality with robust features like sorting and filtering?",
        description: "No",
        ariaControls: "sixth",
      },
      {
        id: 7,
        title:
          "Does the content make effective use of multimedia elements, such as, images, audio, video",
        description: "Yes",
        ariaControls: "seventh",
      },
      {
        id: 8,
        title: "Website has a About Us page",
        description: "Yes",
        ariaControls: "eight",
      },
      {
        id: 9,
        title: "Website has a Contact Us page",
        description: "Yes",
        ariaControls: "nine",
      },
      {
        id: 10,
        title:
          "Is the organizational structure and hierarchy of the department available on the website?",
        description: "Yes",
        ariaControls: "tenth",
      },
      {
        id: 11,
        title:
          "Are the priorities, scope of the department, programmes, flagship schemes outlined on the website?",
        description: "No",
        ariaControls: "eleventh",
      },
      {
        id: 12,
        title:
          "Are key performance indicators available for programmes and organisations?",
        description: "Yes",
        ariaControls: "twelve",
      },
      {
        id: 13,
        title:
          "Does the website have relevant policies like Copyright Policy, Terms & Conditions",
        description: "Yes",
        ariaControls: "thirteen",
      },
    ],
    interactivity_engagement: [
      {
        id: 1,
        title:
          "Are there options for users to provide feedback/ submit inquiries/ participate in surveys, polls, or online consultations on the website?",
        description: "Yes",
        ariaControls: "first",
      },
      {
        id: 2,
        title: "Does the website offer online transactions?",
        description: "Yes",
        ariaControls: "second",
      },
      {
        id: 3,
        title:
          "Are there opportunities for users to participate in surveys, polls, or online consultations on the website?(0) Nothing available (1) One available (2) More than one available",
        description: "No",
        ariaControls: "third",
      },
      {
        id: 4,
        title:
          "Does the website include social media integration, allowing users to share or interact with content through social platforms?",
        description: "Yes",
        ariaControls: "fourth",
      },
      {
        id: 5,
        title:
          "Does the website provide real-time assistance through chatbots or virtual assistants?",
        description: "No",
        ariaControls: "fifth",
      },
      {
        id: 6,
        title:
          "Is it easy to sign up for email updates, newsletters, or alerts about new information",
        description: "Yes",
        ariaControls: "sixth",
      },
    ],
    branding_visual: [
      {
        id: 1,
        title:
          " Is the visual design of the website aligned with the government's overall identity, including branding guidelines, color schemes, logo usage, and visual style?",
        description: "Yes",
        ariaControls: "first",
      },
      {
        id: 2,
        title:
          "Are the logo, fonts, name, headings and other branding elements consistent and placed on same location on the website?",
        description: "Yes",
        ariaControls: "second",
      },
      {
        id: 3,
        title: "Does the website indicate ownership information",
        description: "Yes",
        ariaControls: "third",
      },
      {
        id: 4,
        title:
          "Is the website domain name on respective government authorised like .gov.in, .gov, gov.uk etc",
        description: "Yes",
        ariaControls: "fourth",
      },
    ],
    navigation: [
      {
        id: 1,
        title: "The website has a sitemap",
        description: "Yes",
        ariaControls: "first",
      },
      {
        id: 2,
        title: "Website has a FAQ",
        description: "Yes",
        ariaControls: "second",
      },
      {
        id: 3,
        title: "Navigation components are consistent across the site",
        description: "No",
        ariaControls: "third",
      },
      {
        id: 4,
        title: "Homepage linked to all pages on the website",
        description: "No",
        ariaControls: "fourth",
      },
      {
        id: 5,
        title: "The website has breadcrumbs",
        description: "Yes",
        ariaControls: "fifth",
      },
      {
        id: 6,
        title: "Click depth is equal to or less than 3",
        description: "No",
        ariaControls: "sixth",
      },
      {
        id: 7,
        title:
          "Correct title of the homepage (appearing on the top bar of the browser) is provided",
        description: "Yes",
        ariaControls: "seventh",
      },
      {
        id: 8,
        title: "Website Links are distinguishable in terms of colour, format",
        description: "Yes",
        ariaControls: "eight",
      },
      {
        id: 9,
        title: "Website links are functional and no broken links",
        description: "Yes",
        ariaControls: "ninth",
      },
      {
        id: 10,
        title:
          "Are there any web page error messages like 404/500 appear/displayed on any page under the website",
        description: "Yes",
        ariaControls: "tenth",
      },
    ],
  };

  const [dashStatus,setDashStatus] = useState('');

  const getSiteDashData = () =>{
    axios
      .get(
        // `https://api.front.webalytics.checkbrand.companydemo.in/api/sites/insights/${siteData[0]?._id?.$oid}`,
        `https://api.front.webalytics.checkbrand.companydemo.in/api/sites/demo/658d4080b1a23ff327095662/`,

        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        setDashData(res.data?.data);
        setDashStatus(res.data.status);
        console.log("Dash Data is", res.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error of dash Data ", error);
        setLoading(false)
      });
  }
  useEffect(()=>{
    getSiteDashData();
  },[])

  useEffect(() => {
    if (siteData?.length > 0) {
      // getSiteData();
      setRefreshType({...refreshType,id:siteData[0]?._id?.$oid})
    }
  }, [siteData]);

  useEffect(()=>{
    if(dashStatus === false){
      setIsBlurred(true)
    }
  },[dashStatus])

  console.log("Dash Status",dashStatus)

  // const [refreshType, setRefreshType] = useState({
  //   id: siteData[0]?._id?.$oid,
  //   type: "site",
  // });

  // const [refreshStatus,setRefreshStatus] = useState('');

  // const [refreshData,setRefreshData] = useState('');
  // const [requestData,setRequestData] = useState('');
  // const [shouldcallApi,setShouldCallApi] = useState(true)

  // const refreshApi = () => {
  //   axios
  //     .post(
  //       `https://api.front.webalytics.checkbrand.companydemo.in/api/liveref`,
  //       refreshType ,{
  //         headers: {
  //           accept: "*/*",
  //           "Auth-Token": `${token}`,
  //         },
  //       }, 
  //     )
  //     .then((res) => {
  //       console.log("Live Reporting", res.data.data.request_id?.$oid);
  //       setRefreshStatus(res.data?.status);
  //       setRefreshData(res.data)
  //     })
  //     .catch((error) => {
  //       console.log("Error in live reporting data", error);
  //     });
  // };

  // useEffect(()=>{
  //   if(refreshData){
  //     console.log("Refresh Data",refreshData);
  //     if(shouldcallApi){
  //       setInterval(() => {
  //         requestedData();
  //       }, 60000);
  
  //     }
     
  //   }
  // },[refreshData])


  // const requestedData = () =>{
  //  axios
  //     .post(
  //       `https://api.front.webalytics.checkbrand.companydemo.in/api/liveref`,
  //       {
  //         id:refreshData?.data?.request_id?.$oid,
  //         type: 'request', // Spread the updated state
  //       } ,{
  //         headers: {
  //           accept: "*/*",
  //           "Auth-Token": `${token}`,
  //         },
  //       }, 
  //     )
  //     .then((res) => {
  //       console.log("Reuqested Reporting Data", res.data);
  //       setShouldCallApi(res.data?.status)
  //     })
  //     .catch((error) => {
  //       console.log("Error in Requested reporting data", error);
  //     })};

  //     useEffect(()=>{
  //       if(!shouldcallApi){
  //         const currentDate = new Date();
  //        const options = { day: "numeric", month: "long", year: "numeric" };
  //        const formattedDate = currentDate.toLocaleDateString("en-US", options);
  //       const message = `Updated Reports as of ${formattedDate}`;
  //       setUpdateMessage(message);
  //       setRefreshStatus('')
  //       }
  //     },[shouldcallApi])

  const [refreshType, setRefreshType] = useState({
    id: siteData[0]?._id?.$oid,
    type: "site",
  });

  const [refreshStatus, setRefreshStatus] = useState('');
  const [refreshData, setRefreshData] = useState('');
  const [refreshId,setRefreshId] = useState('');
  const [shouldCallApi, setShouldCallApi] = useState(true);

  const refreshApi = () => {
    axios
      .post(
        `https://api.front.webalytics.checkbrand.companydemo.in/api/liveref`,
        refreshType,
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        },
      )
      .then((res) => {
        console.log("Live Reporting", res.data?.data?.request_id?.$oid);
        setRefreshStatus(res.data?.status);
        setRefreshData(res.data);
        setRefreshId(res.data?.data?.request_id?.$oid)
      })
      .catch((error) => {
        console.log("Error in live reporting data", error);
      });
  };

  const requestedData = () => {
    axios
      .post(
        `https://api.front.webalytics.checkbrand.companydemo.in/api/liveref`,
        {
          id: refreshData?.data?.request_id?.$oid,
          type: 'request',
        },
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        },
      )
      .then((res) => {
        console.log("Requested Reporting Data", res.data);
        setShouldCallApi(res.data?.status);
      })
      .catch((error) => {
        console.log("Error in Requested reporting data", error);
      });
  };

  useEffect(() => {
    if (siteData?.length > 0) {
      // getSiteData();
      setRefreshType({ ...refreshType, id: siteData[0]?._id?.$oid });
    }
  }, [siteData]);

  useEffect(() => {
    if (!shouldCallApi) {
      const currentDate = new Date();
      const options = { day: "numeric", month: "long", year: "numeric" };
      const formattedDate = currentDate.toLocaleDateString("en-US", options);
      const message = `Updated Reports as of ${formattedDate}`;
      setUpdateMessage(message);
      setRefreshStatus('');
      getSiteDashData();
      setIsBlurred(false)
      setShowModal(false)
    }
  }, [shouldCallApi]);

  const handleButtonClick = () => {
    // Check if refreshStatus is false before making the API call
    if (!refreshStatus) {
      refreshApi();
    }
  };

  // useEffect(()=>{
  //   if (shouldCallApi && refreshId) {
  //     if(isBlurred){
  //       setShowModal(true)
  //     }
  //     else{

  //     }
  //     setTimeout(requestedData, 60000); // Schedule the next call after 1 minute
  //   }
  // },[refreshId])



  const [isBlurred, setIsBlurred] = useState();
  const [showModal,setShowModal] = useState(false)

  console.log("siteData check",siteData)

    useEffect(() => {
  let intervalId;

  const fetchData = () => {
    // Call your API or dispatch the action to fetch data here
    requestedData();
  };

  if (shouldCallApi && refreshId) {
    if (isBlurred) {
      setShowModal(true);

      // Clear the previous interval if any
      clearInterval(intervalId);
    } else {
      // Call the function immediately
      fetchData();

      // Set up an interval to call the function every 60 seconds
      intervalId = setInterval(fetchData, 60000);
    }
  }

  // Clean up the interval when the component unmounts or shouldCallApi becomes false
  return () => clearInterval(intervalId);
}, [refreshId, shouldCallApi, isBlurred]);

console.log("DUMMY DATA IS",dummyDashData)

  return (
    <>
      {loading ? (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              height: "100vh",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
            }}
          >
            <BeatLoader color="#36d7b7" />
          </div>
        </>
      ) : (
        <>
        
          <div className={`wrapper`}>
            {showModal &&
            <Dash_Modal siteData={siteData} /> }
            {/* <!-- Sidebar  --> */}
            <div className={`iq-sidebar ${isBlurred && "blur-content"}`} >
              <div className="iq-sidebar-logo d-flex justify-content-between">
                <a href="/">
                  <img
                    src="/images/checkbrand.png"
                    className="img-fluid"
                    alt=""
                  />
                  {/* <!-- <span>Onboarding</span> --> */}
                </a>
                <div className="iq-menu-bt-sidebar">
                  <div className="iq-menu-bt align-self-center">
                    <div
                      className={`wrapper-menu ${expandSidebar && "open"}`}
                      onClick={() => setExpandSidebar(!expandSidebar)}
                    >
                      <div className="main-circle">
                        <i className="ri-more-fill"></i>
                      </div>
                      <div className="hover-circle">
                        <i className="ri-more-2-fill"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sidebar-scrollbar">
                <nav className="iq-sidebar-menu">
                  <ul id="iq-sidebar-toggle" className="iq-menu">
                    {dashList?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={initialRoute == item.title && "active"}
                          style={{ margin: "10px", cursor: "pointer" }}
                          onClick={() => chnageRoute(item.title)}
                        >
                          <a className="iq-waves-effect">
                            <i className={item.icon}></i>
                            <span>{item.title}</span>
                          </a>
                        </li>
                      );
                    })}
                    <li style={{ marginLeft: "10px" }}>
                      <a className="iq-waves-effect">
                        <i className="ri-dashboard-line"></i>
                        <span>Reports</span>
                      </a>
                    </li>
                    <li style={{ marginLeft: "10px", marginTop: "5px" }}>
                      <a
                        className="iq-waves-effect"
                        target="_blank"
                        href="https://analytics.webalytics.checkbrand.companydemo.in/"
                      >
                        <i className="ri-dashboard-line"></i>
                        <span>Traffic</span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="p-3"></div>
              </div>
            </div>
            {/* <!-- Page Content  --> */}
            <div id="content-page" className="content-page">
              {/* <!-- TOP Nav Bar --> */}
              <div className="iq-top-navbar">
                <div className="iq-navbar-custom">
                  <div className={`iq-sidebar-logo ${isBlurred && "blur-content"}`}>
                    <div className="top-logo">
                      <a href="/" className="logo">
                        <img
                          src="/dashboardImage/logo-light.png"
                          className="img-fluid"
                          alt=""
                        />
                        <span>Super Admin</span>
                      </a>
                    </div>
                  </div>
                  <nav className={`navbar navbar-expand-lg navbar-light p-0 ${showLogout && "exclude-blur"}`} style={{background: "linear-gradient(45deg, #03297a, #0b1030, #26183f, #502552, #8d325f)"}}>
                    <div className="iq-search-bar">
                      <div style={{ display: "inline-flex", gap: "10px" }}>
                        <div
                          className="btn-primary btn"
                          onClick={handleButtonClick}
                          style={{pointerEvents: refreshStatus ? "none" : "auto"}}
                        >
                        {refreshStatus ? <ClipLoader color="white" /> : shouldCallApi ?  "Get Latest Report" : "Reports Generated"}
      </div>  <p style={{margin:"auto"}}>
      {isBlurred && `No Reports Generated for ${siteData && siteData[0]?.name} Website`}
      </p>
                        <p style={{ margin: "auto" }}>{updateMessage}</p>
                      </div>

                      {/* <form action="#" className="searchbox">
                    <input
                      type="text"
                      className="text search-input"
                      placeholder="Type here to search..."
                    />
                    <a className="search-link" >
                      <i className="ri-search-line"></i>
                    </a>
                  </form> */}
                    </div>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="ri-menu-3-line"></i>
                    </button>
                    <div className="iq-menu-bt align-self-center">
                      <div className="wrapper-menu">
                        <div className="main-circle">
                          <i className="ri-more-fill"></i>
                        </div>
                        <div className="hover-circle">
                          <i className="ri-more-2-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      {/* <ul className="navbar-nav ms-auto navbar-list">
                    <li className="nav-item iq-full-screen">
                      <a  className="iq-waves-effect" id="btnFullscreen">
                        <i className="ri-fullscreen-line"></i>
                      </a>
                    </li>
                    <li className={`nav-item ${notification && "iq-show"}`} onClick={handleNotificationClick} style={{cursor:'pointer'}}>
                      <a  className={`search-toggle iq-waves-effect ${notification && "active"}`}>
                        <i className="ri-notification-3-fill"></i>
                        <span className="bg-danger dots"></span>
                      </a>
                      <div className="iq-sub-dropdown">
                        <div className="iq-card shadow-none m-0">
                          <div className="iq-card-body p-0 ">
                            <div className="bg-primary p-3">
                              <h5 className="mb-0 text-white">
                                All Notifications{" "}
                                <small className="badge  badge-light float-right pt-1">
                                  4
                                </small>
                              </h5>
                            </div>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">Emma Watson Bini</h6>
                                  <small className="float-right font-size-12">
                                    Just Now
                                  </small>
                                </div>
                              </div>
                            </a>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">New customer is join</h6>
                                  <small className="float-right font-size-12">
                                    5 days ago
                                  </small>
                                </div>
                              </div>
                            </a>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">Two customer is left</h6>
                                  <small className="float-right font-size-12">
                                    2 days ago
                                  </small>
                                </div>
                              </div>
                            </a>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">New Mail from Fenny</h6>
                                  <small className="float-right font-size-12">
                                    3 days ago
                                  </small>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className={`nav-item dropdown ${message && "iq-show"}` }onClick={handleMessageClick} style={{cursor:'pointer'}}>
                      <a  className={`search-toggle iq-waves-effect ${message && "active"}`}>
                        <i className="ri-mail-open-fill"></i>
                        <span className="bg-primary count-mail"></span>
                      </a>
                      <div className="iq-sub-dropdown">
                        <div className="iq-card shadow-none m-0">
                          <div className="iq-card-body p-0 ">
                            <div className="bg-primary p-3">
                              <h5 className="mb-0 text-white">
                                All Messages{" "}
                                <small className="badge  badge-light float-right pt-1">
                                  3
                                </small>
                              </h5>
                            </div>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">Bini Emma Watson</h6>
                                  <small className="float-left font-size-12">
                                    13 Jun
                                  </small>
                                </div>
                              </div>
                            </a>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                                  <small className="float-left font-size-12">
                                    20 Apr
                                  </small>
                                </div>
                              </div>
                            </a>
                            <a  className="iq-sub-card">
                              <div className="media align-items-center">
                                <div className="">
                                  <img
                                    className="avatar-40 rounded"
                                    src="/dashboardImage/user/1.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="media-body ms-3">
                                  <h6 className="mb-0 ">Why do we use it?</h6>
                                  <small className="float-left font-size-12">
                                    30 Jun
                                  </small>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul> */}
                    </div>
                    <ul className="navbar-list" style={{ cursor: "pointer" }}>
                      <li
                        className={showLogout && "iq-show"}
                        onClick={handleLogoutClick}
                      >
                        <a
                          className={`${dash_json?.website_logo ? dash_json?.website_logo : "search-toggle iq-waves-effect d-flex align-items-center"} ${
                            showLogout && "active"
                          }`}
                        >
                          <img
                            src={`${dashData?.website_logo ? "https://checkbrand.online/frontend/images/profile_pic_17035912201424883525.jpeg" : "https://checkbrand.online/frontend/images/profile_pic_17035912201424883525.jpeg"}`}
                            className="img-fluid rounded me-3"
                            alt="user"
                          />
                          {/* <div className="caption">
                            <h6
                              className="mb-0 line-height"
                              style={{ fontSize: "0.8em" }}
                            >
                              {dashData?.name}
                            </h6>
                          </div> */}
                        </a>
                        <div className="iq-sub-dropdown iq-user-dropdown">
                          <div className="iq-card shadow-none m-0">
                            <div className="iq-card-body p-0 ">
                              <div className="bg-primary p-3">
                                <h5 className="mb-0 text-white line-height">
                                  Super Admin
                                </h5>
                                {/* <!-- <span className="text-white font-size-12">Available</span> --> */}
                              </div>
                              <a className="iq-sub-card iq-bg-primary-hover">
                                <div className="media align-items-center">
                                  <div className="rounded iq-card-icon iq-bg-primary">
                                    <i className="ri-file-user-line"></i>
                                  </div>
                                  <div className="media-body ms-3">
                                    <h6 className="mb-0 ">My Profile</h6>
                                    <p className="mb-0 font-size-12">
                                      View personal profile details.
                                    </p>
                                  </div>
                                </div>
                              </a>
                              <a className="iq-sub-card iq-bg-primary-hover">
                                <div className="media align-items-center">
                                  <div className="rounded iq-card-icon iq-bg-primary">
                                    <i className="ri-profile-line"></i>
                                  </div>
                                  <div className="media-body ms-3">
                                    <h6 className="mb-0 ">Edit Profile</h6>
                                    <p className="mb-0 font-size-12">
                                      Modify your personal details.
                                    </p>
                                  </div>
                                </div>
                              </a>
                              <div
                                className="d-inline-block w-100 text-center p-3"
                                onClick={logout}
                              >
                                <a
                                  className="bg-primary iq-sign-btn"
                                  role="button"
                                >
                                  Sign out{" "}
                                  <i className="ri-login-box-line ml-2"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {showTab(initialRoute)}
              {/* <!-- TOP Nav Bar END --> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
