import React from 'react'

const Dash_Footer = () => {
  return <>
  <footer className="bg-white iq-footer mt-4">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 text-right" style={{color:'black'}}>
                    {" "}
                    Copyright 2023 <a href="#">CheckBrand</a> All Rights Reserved.{" "}
                  </div>
                </div>
              </div>
            </footer>
  </>
}

export default Dash_Footer
