import React, { useState } from "react";
import Common_Comp from "./Common_Comp";
import Dash_Footer from "./Dash_Footer";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Common_Dash = ({
  dash_json,
  initialRoute,
  questions,
  slug,
  isBlurred,
}) => {
  const [activeItem, setActiveItem] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [remarks,setRemarks] = useState(false);
  const [reccomendation,setReccomendation] = useState(false);
  const [modalIndex,setModalIndex] = useState(0);
  

  const handleModalOpen = (index) =>{
    setModalIndex(index);
    setOpen(true);
  }
  const handleItemClick = (index) => {
    setActiveItem(index === activeItem ? -1 : index);
  };

  const description = (description) => {
    switch (description) {
      case ``:
        return <> No</>;
      case false:
        return <>No</>;
      case true:
        return <>Yes</>;
      default:
        return <> {description} </>;
    }
  };

  const shouldRenderDiv = (index) => {
    switch (slug) {
      case "security_privacy":
        return index === 0;
      case "performance_technical":
        return index === 2;
      case "mobile_responsive":
        return [0, 1, 5].includes(index);
      case "content_information":
        return index === 3;
      default:
        return false;
    }
  };
  console.log("Slug is", slug);

  return (
    <>
      <section
        className={`ruralDevtSection pt-5 pb-5 mb-5 ${
          isBlurred && "blur-content"
        } hideQuestionBg`}
        style={{
          boxShadow: "none !important",
          backgroundColor: "transparent !important",
        }}
      >
        <div className="container">
          <div className="d-flex gap-3  align-items-center mb-4 headingArea">
            <div>
              <img src="https://checkbrand.online/frontend/images/profile_pic_17035912201424883525.jpeg" alt="Dashboard" />
            </div>
            <div>
              <h1>{initialRoute && initialRoute}</h1>
            </div>
          </div>
          {/* <!-- ./heading --> */}

          <div className="accordion faqSectionArea" id="regularAccordionRobots">
            {questions?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2
                  id={`regularHeading${item?.ariaControls}`}
                  className="accordion-header"
                >
                  <button
                    className={`accordion-button ${
                      index === activeItem ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleItemClick(index)}
                    aria-expanded={index === activeItem}
                    aria-controls={`regularCollapse${item?.ariaControls}`}
                  >
                    <span>Q{item.id}</span>
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`regularCollapse${item?.ariaControls}`}
                  className={`accordion-collapse collapse ${
                    index === activeItem ? "show" : ""
                  } `}
                  aria-labelledby={`regularHeading${item?.ariaControls}`}
                  data-bs-parent="#regularAccordionRobots"
                >
                  <div className="accordion-body">
                    <div className="increArea">
                      <h1 style={{ color: "red" }}>Result</h1>
                      <div style={{ display: "inline-flex", gap: "100px" }}>
                      <span style={{ display: "inline-flex" }}>
                        <p
                          style={{
                            width: "fit-content",
                            backgroundColor: "#051b72",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            color: "white",
                            fontWeight: "normal",
                            fontSize: "14px",
                          }}
                        >
                          {description(item?.description)}
                        </p>
                      </span>
                      <span style={{ display: "inline-flex" }}>
                        <p
                          style={{
                            width: "fit-content",
                            backgroundColor: "#051b72",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            color: "white",
                            fontWeight: "normal",
                            fontSize: "14px",
                          }}
                        >
                          Score
                        </p>
                        <p
                          style={{
                            width: "fit-content",
                            backgroundColor: "#f7c84c",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            color: "black",
                            fontWeight: "normal",
                            fontSize: "14px",
                          }}
                        >
                          {item?.score}
                        </p>
                      </span>
                    </div>
                    </div>
                    <div
                      className="increArea"
                      style={{ height: "fit-content" }}
                    >
                      <h1>Remarks</h1>
                      <p className={` ${!remarks && "line-clamp"}`} style={{ fontWeight: "normal" }}>
                        The website serves as a digital gateway,
                        delivering vital information, services, and resources.
                        Accessible and user-friendly, it fosters civic
                        engagement for a more informed and connected
                        society.Empowering citizens with transparent governance,
                        our website serves as a digital gateway,
                        delivering vital information, services, and resources.
                        Accessible and user-friendly, it fosters civic
                        engagement for a more informed and connected
                        society.Empowering citizens with transparent governance,
                        our website serves as a digital gateway,
                        delivering vital information, services, and resources.
                        Accessible and user-friendly, it fosters civic
                        engagement for a more informed and connected society.
                      </p>
                      {!remarks &&
                      <span style={{ cursor: 'pointer', color: '#007BFF', textDecoration: 'underline' }} onClick={()=>setRemarks(!remarks)}>Read more</span>
}
                    </div>
                    <div className="increArea">
                      <h1 style={{ color: "red" }}>AI Recommendations</h1>
                      <p className={`${!reccomendation && "line-clamp"}`} style={{ fontWeight: "bold" ,fontSize:'20px' }}>
                       Coming Soon...
                      </p>
                      {/* {!reccomendation &&
                      <span
                      style={{ cursor: 'pointer', color: '#007BFF', textDecoration: 'underline' }}
                      onClick={() => setReccomendation(!reccomendation)}
                    >
                      Read more
                    </span>} */}
                    </div>
                    

                    {item?.data && (
                      <div className="d-flex" style={{ marginTop: "20px" }}>
                        <div className="col ps-3 pe-3">
                          <table className="DetailTable">
                            <tbody>
                              {item?.data && (
                                <div className="d-flex">
                                  <div className="col ps-3 pe-3">
                                    <table className="DetailTable">
                                      <tbody>
                                        {Object.keys(item?.data).map((key) => (
                                          <tr key={key}>
                                            <td>{key}</td>
                                            <td>{item?.data[key]}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}{" "}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    {item?.screenPresent && (
                      <>
                      <div className="b-im-panel">
                        {item?.screens?.map((item, index) => (
                          <div key={index} className="image-container" style={{cursor:'pointer'}} onClick={()=>handleModalOpen(index)}>
                            <img
                              src={`https://api.front.webalytics.checkbrand.companydemo.in/public/screens/${item}`}
                            />
                          </div>
                        ))}
                      </div>
                      <Lightbox
                      open={open}
                      index={modalIndex}
                      on={{ view: ({ index: currentIndex }) => setModalIndex(currentIndex) }}
                      close={() => setOpen(false)}
                      slides={item?.screens?.map((src) => ({ src:`https://api.front.webalytics.checkbrand.companydemo.in/public/screens/${src}` }))}
                    />
                    </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <Common_Comp dash_json={dash_json} /> */}
      {/* <Dash_Footer /> */}
    </>
  );
};

export default Common_Dash;
